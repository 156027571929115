// Import jQuery
import $ from 'jquery';
import { BROCHURE_LINK, CONSOLE_LOG, PHP_SERVER_URL } from './constants';

export const HAS_EXTERNAL_PATH = hasExternalPathFunction();

if (CONSOLE_LOG) console.log("HAS_EXTERNAL_PATH:", HAS_EXTERNAL_PATH);

const brBtnlinks = document.querySelectorAll('a[data-br-download]');
if(brBtnlinks){
    brBtnlinks.forEach(link => {
        const key = link.getAttribute('data-br-download');
        if (BROCHURE_LINK[key]) {
            link.setAttribute('href', BROCHURE_LINK[key]);  // Set the href
        }
    });
}


function hasExternalPathFunction() {
    const path = window.location.pathname;
    return path.split('/').length > 2;
}

// Function to shuffle the testimonials array
export function shuffleArrayObject(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getCurrentPageIndex() {
  // Get the current URL
  const currentUrl = window.location.href;

  // Parse the URL to get the path
  const url = new URL(currentUrl);
  const path = url.pathname;

  // Split the path into segments
  const segments = path.split('/').filter(segment => segment.length > 0);

  // Determine the current index
  const currentIndex = segments.length;

  // Output the result
  // if (CONSOLE_LOG) console.log(`CurrentURL: ${currentUrl}`);
  // if (CONSOLE_LOG) console.log(`CurrentIndex: ${currentIndex}`);
  
  return currentIndex;
}

export function modifyPageURL(pageUrl) {
  // Determine the current index
  const currentIndex = getCurrentPageIndex();
  if (CONSOLE_LOG) console.log("currentIndex - ", currentIndex);

  // Check if the URL should be skipped
  if (!shouldSkipUrl(pageUrl)) { // Fixed the 'url' to 'pageUrl' here
    if (currentIndex === 2) {
      pageUrl = "../../" + pageUrl;
    } else if (currentIndex === 3) {
      pageUrl = "../../../" + pageUrl;
    } else if (currentIndex === 4) {
        pageUrl = "../../../../" + pageUrl;
    } else {
      pageUrl = "../" + pageUrl;
    }
  }

  // Output the result
  //if (CONSOLE_LOG) console.log(`PageURL: ${pageUrl}`);
  return pageUrl;
}

// The `shouldSkipUrl` function definition should be outside or imported
export function shouldSkipUrl(url) {
  const skipPrefixes = ['https://', 'mailto:', 'tel:'];
  return skipPrefixes.some(prefix => url.startsWith(prefix));
}



export async function fetchDataFromPHP(endpoint, formObject) {
    try {
        const response = await fetch(`${PHP_SERVER_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formObject),
        });

        // Handle non-200 HTTP status codes
        if (!response.ok) {
            console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
            //throw new Error(`Server responded with error: ${response.status} ${response.statusText}`);
        }

        const contentType = response.headers.get('Content-Type');
        
        // Handle JSON and text responses based on Content-Type
        let responseData;
        if (contentType?.includes('application/json')) {
            responseData = await response.json();
            if (CONSOLE_LOG) console.log('Parsed JSON:', responseData);
        } else if (contentType?.includes('text/')) {
            responseData = await response.text();
            if (CONSOLE_LOG) console.log('Raw Text Response:', responseData);
        } else {
            throw new Error('Unsupported response format from the server.');
        }

        // Validate JSON response if applicable
        if (typeof responseData === 'object' && responseData !== null) {
            if (responseData.status === 200) {
                if (CONSOLE_LOG) console.log('Success:', responseData);
                //alert(responseData.message);
                return responseData;
            } else {
                console.warn('Server returned an error in the response:', responseData.message);
                alert(responseData.message || 'Unexpected response from server.');
                return false;
            }
        }

        // Handle raw text responses or fallback behavior
        if (typeof responseData === 'string') {
            if (CONSOLE_LOG) console.log('Handling raw text response:', responseData);
            // You can add custom logic for text responses here
            return responseData; // or handle it as per your application logic
        }

        // Fallback for unexpected cases
        console.error('Unhandled response format:', responseData);
        alert('Unhandled response format from server.');
        return false;

    } catch (error) {
        console.error('Network or Fetch Error:', error.message || error);
        alert('An error occurred while communicating with the server. Please try again later.');
        return false;
    }
}





  // Function to create and display the modal
  function createAndShowModal(title, body, btns) {
      // Start building the button HTML
      let buttonsHtml = '';

      // Check if t1 exists and add the primary button
      if (btns.t1) {
          buttonsHtml += `
              <button type="button" class="btn btn-primary" id="primaryButton">${btns.t1}</button>
          `;
      }

      // Check if t2 exists and add the secondary button
      if (btns.t2) {
          buttonsHtml += `
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="secondaryButton">${btns.t2}</button>
          `;
      }

      // Create the modal HTML
      const modalHtml = `
          <div class="modal fade" id="dynamicModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="dynamicModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h1 class="modal-title fs-5" id="dynamicModalLabel">${title}</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          ${body}
                      </div>
                      <div class="modal-footer">
                          ${buttonsHtml}
                      </div>
                  </div>
              </div>
          </div>
      `;

      // Append modal to the body
      $('body').append(modalHtml);

      // Show the modal using Bootstrap's jQuery API
      const modalElement = $('#dynamicModal');
      modalElement.modal('show');

      // Handle primary button click
      $('#primaryButton').on('click', function () {
          if (btns.t1Link !== 0) {
              window.location.href = btns.t1Link; // Redirect to the specified link
          } else {
              modalElement.modal('hide'); // Just hide the modal if no link is provided
          }
      });

      // Remove the modal from DOM when it is closed
      modalElement.on('hidden.bs.modal', function () {
          modalElement.remove();
      });
  }


  // Trigger the modal
  //createAndShowModal('Form Submission Successful', 'Your form has been submitted successfully!', { t1: "Okay", t1Link: 0 });




